<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px">
    <main class="page-content w-100">
      <div class="container-fluid row">
        <ul
          class="breadcrumb ProximaNovaRegular col"
          style="margin-left: 52px; margin-top: 12px"
        >
          <li>
            <router-link to="/solutions/digital-poem/model-results">
              <span>Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/digital-poem/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/digital-poem/attribution">
              <span>Future Planning</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/digital-poem/planning-result">
              <span>Planning Result</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Scenerio planning</li>
        </ul>
      </div>

      <!-- tab -->
      <div class="container-fluid">
        <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
        </w-tab>
      </div>
      <!-- Digital -->
      <div
        class="w-100  prediction-section"
        v-if="activeTab === 'Digital Media'"
      >
        <div class="w-100" style="position: sticky; top: 71px; z-index: 9;">
          <div class="container-fluid tab-panel m-0 p-0">
            <div
              class="card w-100"
              style="box-shadow: rgb(233 235 239) 1px 2px 13px 5px;"
            >
              <div class="card-body p-4">
                <div
                  class="card-header font-large ProximaNovaBold"
                  style="
                        padding: 0;
                        background: transparent;
                        border: 0px !important;
                      "
                >
                  Prediction
                  <div class="caption mt-3">
                    <span
                      :class="
                        activeLevelTwo === '' && activeLevelOne === ''
                          ? 'text-bold'
                          : ''
                      "
                      >{{ breadcrumb.first }}</span
                    >
                    <span
                      v-if="activeLevelOne !== ''"
                      :class="
                        activeLevelOne !== '' && activeLevelTwo === ''
                          ? 'text-bold'
                          : ''
                      "
                      ><i class="fa fa-chevron-right mx-3"></i
                      >{{ breadcrumb.second }}</span
                    >
                    <span
                      v-if="activeLevelTwo !== ''"
                      :class="activeLevelTwo !== '' ? 'text-bold' : ''"
                      ><i class="fa fa-chevron-right mx-3"></i
                      >{{ breadcrumb.third }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid tab-panel card__body_content pt-5">
          <div class="row fix-width" style="flex-wrap: initial;">
            <div class="w-100 d-flex pb-5" style="overflow-x: auto;">
              <div class="col p-0" style="height: fit-content">
                <div
                  class="newcard levelone"
                  :class="[
                    activeLevelOne === levelOne.name ? 'active' : '',
                    activeLevelOne !== '' && activeLevelOne !== levelOne.name
                      ? 'fadeOutBox'
                      : '',
                    activeLevelOne === levelOne.name ? 'border_right_side' : '',
                  ]"
                  v-for="(levelOne, index) in chartData"
                  :key="index"
                >
                  <div
                    @click="
                      showLevelTwoData(
                        levelOne.name,
                        levelOne.secondLevelData,
                        index
                      )
                    "
                    class="w-100 py-3 px-4 d-flex font-medium ProximaNovaBold mb-5"
                    style="color:#222a37;justify-content: space-between;align-items: center;"
                  >
                    <div>
                      <span>{{ levelOne.name }}</span>
                    </div>
                    <div class="imagetoggle">
                      <img
                        src="@/assets/eye-line.png"
                        alt="close"
                        height="30px"
                        v-if="activeLevelOne === levelOne.name"
                      />
                      <img
                        src="@/assets/eye-close-line.svg"
                        alt="close"
                        height="30px"
                        v-else
                      />
                    </div>
                  </div>
                  <div class="">
                    <Slider
                      class="px-3"
                      :defaultValue="levelOne.value"
                      :pathColor="levelOne.pathColor"
                      :rangeColor="levelOne.backgroundColor"
                      @updatedValue="updateLevelOneValue($event, index)"
                    />
                  </div>
                  <div
                    style="height:21%;"
                    @click="
                      showLevelTwoData(
                        levelOne.name,
                        levelOne.secondLevelData,
                        index
                      )
                    "
                  ></div>
                </div>
              </div>
              <div
                class="col p-0"
                :style="{ top: alignLevelTwoData + 'px' }"
                style="height: fit-content"
              >
                <div
                  class="w-100 wrapperLevelTwo  "
                  :class="[
                    activeLevelOne !== '' ? 'border_top' : '',
                    activeLevelOne !== '' ? 'border_bottom' : '',
                  ]"
                >
                  <div
                    class="newcard leveltwo my-4 "
                    v-for="(levelOne, index) in activeLevelTwoData"
                    :key="index"
                    :class="[
                      activeLevelTwo === levelOne.name ? 'active' : '',
                      activeLevelTwo !== '' && activeLevelTwo !== levelOne.name
                        ? 'fadeOutBox'
                        : '',
                      activeLevelTwo === levelOne.name
                        ? 'border_right_side'
                        : '',
                    ]"
                  >
                    <div
                      class="w-100 py-3 px-4 d-flex font-medium ProximaNovaBold mb-5"
                      style="color:#222a37;justify-content: space-between;align-items: center;"
                      @click="
                        showLevelThreeData(
                          levelOne.name,
                          levelOne.thirdLevelData,
                          index
                        )
                      "
                    >
                      <div>
                        <span>{{ levelOne.name }}</span>
                      </div>
                      <div class="imagetoggle">
                        <img
                          src="@/assets/eye-line.png"
                          alt="close"
                          height="30px"
                          v-if="activeLevelTwo === levelOne.name"
                        />
                        <img
                          src="@/assets/eye-close-line.svg"
                          alt="close"
                          height="30px"
                          v-else
                        />
                      </div>
                    </div>
                    <div class="">
                      <Slider
                        class="px-3"
                        :defaultValue="levelOne.value"
                        :pathColor="levelOne.pathColor"
                        :rangeColor="levelOne.backgroundColor"
                        @updatedValue="updateLevelTwoValue($event, index)"
                      />
                    </div>
                    <div
                      style="height:21%;"
                      @click="
                        showLevelThreeData(
                          levelOne.name,
                          levelOne.thirdLevelData,
                          index
                        )
                      "
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="col p-0"
                style="height: fit-content"
                :style="{ top: alignLevelThreeData + 'px' }"
              >
                <div
                  class="w-100 wrapperLevelTwo"
                  :class="[
                    activeLevelTwo !== '' ? 'border_top' : '',
                    activeLevelTwo !== '' ? 'border_bottom' : '',
                  ]"
                >
                  <div
                    class="newcard leveltwo my-4 "
                    v-for="(levelOne, index) in activeLevelThreeData"
                    :key="index"
                  >
                    <div
                      class="w-100 py-3 px-4 d-flex font-medium ProximaNovaBold mb-5"
                      style="color:#222a37;justify-content: space-between;align-items: center;"
                    >
                      <div>
                        <span>{{ levelOne.name }}</span>
                      </div>
                    </div>
                    <div class="">
                      <Slider
                        class="px-3"
                        :defaultValue="levelOne.value"
                        :pathColor="levelOne.pathColor"
                        :rangeColor="levelOne.backgroundColor"
                        @updatedValue="updateLevelThreeValue($event, index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <w-scenarioplanning
        v-if="activeTab === 'Additional Details'"
      ></w-scenarioplanning>
      <div class=" my-3 w-100 d-flex" style="justify-content: end;">
        <w-button
          style="float: none;"
          :buttonText="'Predict'"
          :buttonLoader="buttonLoader"
          @buttonClicked="handleClick"
        >
        </w-button>
      </div>
    </main>
  </div>
</template>

<script>
import Tab from "@/components/Solutions/Tab.vue";
import AdditionalDetails from "@/pages/Scenario/AdditionalDetails.vue";
import Button from "@/components/Profile/Button.vue";
import Slider from "@/widgets/Slider.vue";
import { AIDigitalPoem } from "@/services/AIDigitalPoemServices";
const aiDigitalPoem = new AIDigitalPoem();
export default {
  name: "ScenarioPlanning",
  components: {
    "w-tab": Tab,
    "w-scenarioplanning": AdditionalDetails,
    "w-button": Button,
    Slider,
  },
  data() {
    return {
      buttonLoader: "normal",
      activeLevelTwo: "",
      activeLevelOne: "",
      activeLevelTwoData: [],
      activeLevelThreeData: [],
      activeLevelOneIndex: 0,
      activeLevelTwoIndex: 0,
      chartData: [
        {
          name: "Instagram1",
          value: 10,
          backgroundColor: "rgb(106,27,154)",
          secondLevelData: [
            {
              name: "Display Ads1",
              value: 10,
              backgroundColor: "#0033ff",
            },
          ],
        },
        {
          name: "Instagram111",
          value: 10,
          backgroundColor: "#0033ff",
          secondLevelData: [
            {
              name: "Display Ads1",
              value: 10,
              backgroundColor: "#0033ff",
            },
          ],
        },
        {
          name: "Instagram123",
          value: 10,
          backgroundColor: "#0033ff",
          secondLevelData: [
            {
              name: "Display Ads1",
              value: 10,
              backgroundColor: "#0033ff",
            },
          ],
        },
        {
          name: "Instagram",
          value: 10,
          backgroundColor: "#0033ff",
          secondLevelData: [
            {
              name: "Display Ads",
              value: 10,
              backgroundColor: "#0033ff",
            },
            {
              name: "Display Ads22",
              value: 10,
              backgroundColor: "#0033ff",
              thirdLevelData: [
                {
                  name: "Display Ads",
                  value: 10,
                  backgroundColor: "#0033ff",
                },
                {
                  name: "Display Ads",
                  value: 10,
                  backgroundColor: "#0033ff",
                },
              ],
            },
          ],
        },
      ],
      breadcrumb: {
        first: "Channel",
        second: "Ad Type",
        third: "Campaign Type",
      },
      activeTab: "Digital Media",
      tabs: [
        {
          category: "Digital Media",
        },
        // {
        //   category: "Content",
        // },
        {
          category: "Additional Details",
        },
      ],
      actualValues: {},
      colors: [
        "#1877f2",
        "#dd2a7b",
        "#148f3a",
        "#0077b5",
        "#b5a9ff",
        "#ffdd7c",
        "#08a0e9",
        "#dd2a7b",
        "#1877f2",
      ],
    };
  },
  created() {
    this.getSliderData()
  },
  mounted() {},
  computed: {
    alignLevelTwoData() {
      if (this.activeLevelOneIndex + 1 === this.chartData.length) {
        return (this.chartData.length - this.activeLevelTwoData.length) * 177;
      }
      if (this.activeLevelOneIndex + 1 > this.activeLevelTwoData.length) {
        return this.activeLevelOneIndex * 177;
      }
      return 0;
    },
    alignLevelThreeData() {
      if (this.activeLevelTwoIndex + 1 > this.activeLevelThreeData.length) {
        return this.activeLevelTwoIndex * 177 + this.alignLevelTwoData;
      }
      return 0 + this.alignLevelTwoData;
    },
  },
  methods: {
    getColor(color, index) {
      var opacity;
      if (index >= 5) {
        opacity = 0.5;
      } else {
        opacity = (10 - index) / 10;
      }
      var x = color.split(")");
      return x[0] + "," + opacity + ")";
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    updateLevelOneValue(e, i) {
      this.chartData[i].value = e;
    },
    updateLevelTwoValue(e, i) {
      this.activeLevelTwoData[i].value = e;
    },
    updateLevelThreeValue(e, i) {
      this.activeLevelThreeData[i].value = e;
    },

    showLevelTwoData(e, data, i) {
      this.activeLevelTwo = "";
      this.activeLevelThreeData = [];
      this.activeLevelTwoIndex = 0;
      if (e === this.activeLevelOne) {
        this.activeLevelOne = "";
        this.activeLevelTwoData = [];
        this.activeLevelOneIndex = 0;
        return;
      }
      this.activeLevelOne = e;
      this.activeLevelTwoData = data;
      this.activeLevelOneIndex = i;
    },
    showLevelThreeData(e, data, i) {
      if (e === this.activeLevelTwo) {
        this.activeLevelTwo = "";
        this.activeLevelThreeData = [];
        this.activeLevelTwoIndex = 0;
        return;
      }
      this.activeLevelTwo = e;
      this.activeLevelThreeData = data;
      this.activeLevelTwoIndex = i;
    },
    getSliderData() {
      this.chartData = [];
      aiDigitalPoem
        .getExpectedRevenue(
          sessionStorage.getItem("runId"),
          sessionStorage.getItem("actualGrowth"),
          sessionStorage.getItem("targetVariable")
        )
        .then((res) => {
          this.actualValues = res.response.result;
          var levelOneColor = ["rgb(106,27,154)", "rgb(216,27,96)"];
          res.response.result_in_percentage.mediaData.forEach((ele, index) => {
            var levelThree = [];
            var levelTwo = [];
            var i;
            var j;
            for (i = 0; i < ele.levelOne.length; i++) {
              let levelthreeData = ele.levelTwo.find(
                (obj) => obj.title === ele.levelTwo[i].title
              ).value;
              levelThree = [];
              for (j = 0; j < levelthreeData.length; j++) {
                levelThree.push({
                  name: levelthreeData[j].name,
                  value: levelthreeData[j].optimised,
                  previous: levelthreeData[j].previous,
                  backgroundColor: this.colors[j],
                });
              }
              levelTwo.push({
                name: ele.levelOne[i].name,
                value: ele.levelOne[i].optimised,
                backgroundColor: this.getColor(levelOneColor[index], i),
                thirdLevelData: levelThree,
                previous: ele.levelOne[i].previous,
              });
            }
            this.chartData.push({
              name: ele.title,
              value: ele.optimised,
              backgroundColor: levelOneColor[index],
              secondLevelData: levelTwo,
            });
          });
        });
    },
    handleClick() {},
  },
};
</script>

<style scoped>
.caption {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727988;
}
.newcard {
  min-width: 522px;
  height: 162px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}
.newcard.active {
  border: 2px solid #000000;
}
.newcard.fadeOutBox {
  background-color: #f2f5fa;
  opacity: 0.5;
}
.newcard.levelone {
  margin-right: 48px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.newcard.leveltwo {
  margin: 15px 48px;
}

.btn-effect {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.border_right_side::before {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: 100%;
  top: 50%;
  width: 50px;
}
.border_top::before {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -1px;
  top: -1px;
  width: 50px;
}
.border_bottom::after {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -2px;
  top: 2px;
  width: 50px;
}

.wrapperLevelTwo {
  border-left: 2px solid #000;
  padding: 1px 0px;
}
.text-bold {
  font-weight: bold;
}
.card__body_content {
  box-shadow: rgb(233 235 239) -2px 4px 20px 11px;
  width: auto;
}
.fix-width {
  max-width: 100%;
  padding-left: 20px;
  padding-bottom: 20px;
}
.prediction-section {
  padding: 0px 30px;
}
.imagetoggle {
  height: 48px;
  width: 48px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}
</style>
